import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import Portfolio from "../components/Portfolio/Portfolio"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Image } from "../components/Core"
// import Seamless from "../components/Seamless"
import Seamless from "../components/SeamlessEmbed"

const singleWeeklyCampaign = ({ data, path }) => {
  const post = data.allWeeklyCampaignsJson.nodes[0]
  const { heading, blurb, results, form, videos } = post
  return (
    <Layout navSpacer className="single-campaign" path={path}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        robots="noindex"
      />

      <Section zeroTop>
        <Container>
          <div className="single-campaign__heading">
            <h1>{heading}</h1>
            {blurb && <MarkdownViewer markdown={blurb} />}
          </div>
        </Container>
      </Section>

      <div className={`sizzle-container has-${videos.length}`}>
        {videos.map(v => (
          <React.Fragment key={v.youtube}>
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${v.youtube}`}
              overVideo={true}
              language={"en"}
              buttonClass="contained">
              <Image
                publicId={v.image}
                width="auto"
                wrapperClassName="theater-image"
              />
            </TheaterVideo>
          </React.Fragment>
        ))}
      </div>

      <div className="single-campaign__assessment">
        <div
          className="single-campaign__assessment-content"
          style={{
            backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/${results.image})`
          }}>
          <MarkdownViewer markdown={results.content} />
        </div>
        <div className="single-campaign__assessment-form">
          <MarkdownViewer markdown={form.heading} />
          <div className="single-campaign__assessment-iframe">
            {/* <Seamless src="/utahCampaignForm.js" /> */}
            <Seamless src={form.iframeSrc} />
          </div>
        </div>
      </div>

      <Portfolio bgColor="tan" />
    </Layout>
  )
}

export default singleWeeklyCampaign

export const singleCampaignQuery = graphql`
  query singleCampaign($title: String!) {
    allWeeklyCampaignsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        heading
        blurb
        videos {
          youtube
          image
        }
        results {
          content
          image
        }
        form {
          heading
          iframeSrc
        }
      }
    }
  }
`
